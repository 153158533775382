body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #f6f9fc;
  color: #32325d;
}

h1, h2 {
  font-weight: 600;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

header {
  background-color: #32325d;
  color: #fff;
  padding: 1.5rem 3rem;
}

.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  padding: 1rem;
}

.column {
  flex: 1;
  padding: 1em;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

th, td {
  padding: 0.5rem 1rem;
  text-align: left;
}

th {
  background-color: #32325d;
  color: #fff;
  font-weight: 600;
}

tr:nth-child(even) {
  background-color: #f6f9fc;
}

tr:nth-child(odd) {
  background-color: #fff;
}

iframe {
  width: 100%;
  height: 700px;
  border: 1px solid #ccc;
  border-radius: 4px;
}